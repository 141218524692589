<template>
  <div
    class="option-container d-flex align-center"
    :class="{'w-full':$vuetify.breakpoint.xs}"
  >
    <v-menu
      bottom
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          outlined
          text
          :color="leadsViewSelected === 'list' ? 'primary' : 'rgba(0, 0, 0, 0.50)'"
          v-bind="attrs"
          class="px-2 camel-case option-btn"
          @click="$emit('select')"

          v-on="on"
        >
          <v-icon left>
            mdi-format-list-bulleted
          </v-icon>
          {{ $t('labels.contacts.list') }}
        </v-btn>
      </template>
    </v-menu>
  </div>
</template>

<script>

export default {
  props: {
    leadsViewSelected: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped>

.option-container {
  margin-right: -1px;
}

.option-btn {
  text-transform: uppercase !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.5px;
  border: none;
}

@media (max-width: 600px) {
  .option-container {
    height: unset !important;

  }
}
@media (min-width: 600px) {
  .option-container {
    height: 50px !important;
  }
}
@media (min-width: 1037px) and (max-width: 1324px) {
  .option-container {
    margin-left: 10px;
  }
}
</style>
