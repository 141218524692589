<template>
  <v-card
    outlined
    class="d-flex flex-column"
    :style="cssProps"
  >
    <v-card-title>
      <h5>{{ $t('delete-leads.title') }}</h5>
      <v-spacer />
      <v-btn
        icon
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <p>
        {{ $t('delete-leads.subtitle') }}
      </p>

      <div>
        <div
          class="mt-4 mb-2 rounded-lg mx-n2 pa-2"
          :class="{'finished-step':isStepContactFilterFinished}"
        >
          <div
            class="d-flex"
          >
            <v-icon
              v-if="isStepContactFilter"
              :class="{'blink':isStepContactFilter}"
              small
            >
              mdi-arrow-right
            </v-icon>
            <h4 :class="{'blink':isStepContactFilter}">
              {{ $t('delete-leads.body-texts.0') }}
            </h4>

            <v-icon
              v-if="isStepContactFilterFinished"
              class="ml-2"
              size="20"
              small
              color="primary"
            >
              mdi-check-circle
            </v-icon>
          </div>
          <p>{{ $t('delete-leads.body-texts.1') }}</p>
          <v-select
            v-model="contactFilter"
            class="mt-2 mb-2"
            outlined
            validate-on-blur
            :items="contactFilters"
            :placeholder="$t('labels.select-contact')"
            hide-details
            dense
            required
          />

          <slot name="selectAll" />
        </div>

        <div
          class="mt-4 mb-2 rounded-lg mx-n2 pa-2"
          :class="{'finished-step':isSubmitted}"
        >
          <div class="d-flex">
            <v-icon
              v-if="isStepSubmit"
              :class="{'blink':isStepSubmit}"
              small
            >
              mdi-arrow-right
            </v-icon>
            <h4 :class="{'blink':isStepSubmit}">
              {{ $t('delete-leads.body-texts.2') }}
            </h4>
            <v-icon
              v-if="isSubmitted"
              class="ml-2"
              size="20"
              small
              color="primary"
            >
              mdi-check-circle
            </v-icon>
          </div>

          <div
            v-if="isLoading"
            class="d-flex flex-column align-center justify-center mb-4"
          >
            <span class="text-center">
              {{ $t('delete-leads.body-texts.3') }}
            </span>
          </div>

          <p v-else>
            {{ $t('delete-leads.body-texts.4') }}
          </p>

          <v-btn
            depressed
            small
            color="error"
            :loading="isDeletingLeads || isLoading"
            :disabled="selectedItemsLength<=0 || isDeletingLeads || isLoading"
            class="w-full"
            @click="dialog=true"
          >
            <v-icon
              small
              class="mr-2"
            >
              mdi-delete
            </v-icon>
            {{ $t('buttons.delete-leads.cta') }}
          </v-btn>
        </div>
      </div>
    </v-card-text>
    <DeleteLeadsDialog
      :dialog="dialog"
      :lead-count="selectedItemsLength"
      @close="dialog=false"
      @delete="submit"
    />
  </v-card>
</template>

<script>
import { DeleteLeadsContactFilter, ReadableDeleteLeadsContactFilter } from '../enums/DeleteLeads'
import { persistFilter, resetAllFilters } from '@/modules/leads/helper/filter'
import AnimationData from '../../../../public/animations/email-plane.json'
import DeleteLeadsDialog from './DeleteLeadsDialog.vue'

export default {
  components: {
    DeleteLeadsDialog
  },
  props: {
    selectedItemsLength: {
      type: Number,
      default: 0
    },
    triggerResetDeleteLeads: {
      type: Boolean,
      default: false
    },
    isDeletingLeads: {
      type: Boolean,
      default: false
    },
    hasCrm: {
      type: Boolean,
      default: false
    },
    crmName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      contactFilter: null,
      state: 'IDLE',
      loadingTimeout: null,
      animationData: AnimationData,
      dialog: false
    }
  },
  computed: {
    contactFilters () {
      const readableDeleteLeadsContactFilter = Object.keys(ReadableDeleteLeadsContactFilter).map(key => ({ value: key, text: this.$t(ReadableDeleteLeadsContactFilter[key]) }))
      if (this.hasCrm) {
        const crmFilter = {
          value: DeleteLeadsContactFilter.CRM,
          text: this.$t('delete-leads.from-crm', { crm: this.crmName })
        }
        readableDeleteLeadsContactFilter.splice((readableDeleteLeadsContactFilter.length - 1), 0, crmFilter)
      }
      if (this.$vuetify.breakpoint.smAndDown) { return readableDeleteLeadsContactFilter.filter(filter => filter.value !== DeleteLeadsContactFilter.CUSTOM) }
      return readableDeleteLeadsContactFilter
    },
    cssProps () {
      return {
        '--primary': this.$vuetify.theme.themes.light.primary,
        '--primary-light': `${this.$vuetify.theme.themes.light.primary}55`
      }
    },
    isStepContactFilterFinished () {
      return this.contactFilter && this.selectedItemsLength > 0
    },
    isStepContactFilter () {
      return this.selectedItemsLength <= 0
    },
    isStepSubmit () {
      return this.selectedItemsLength > 0 && this.contactFilter
    },
    isSubmitted () {
      return this.state === 'SUBMITTED'
    },
    isLoading () {
      return this.state === 'LOADING'
    }
  },

  watch: {
    triggerResetDeleteLeads () {
      if (this.triggerResetDeleteLeads) {
        this.reset()
        this.$emit('triggeredResetDeleteLeads')
      }
    },
    contactFilter () {
      this.state = 'IDLE'
      if (this.contactFilter !== DeleteLeadsContactFilter.CUSTOM) {
        resetAllFilters()
        if (this.contactFilter === DeleteLeadsContactFilter.PROPERTY_REQUEST) {
          persistFilter('propertyRequests', true)
        } else if (this.contactFilter === DeleteLeadsContactFilter.VALUATIONS) {
          persistFilter('valuations', true)
        } else if (this.contactFilter === DeleteLeadsContactFilter.NEW) {
          persistFilter('currentStatus', 'new')
        } else if (this.contactFilter === DeleteLeadsContactFilter.CRM) {
          persistFilter('leadOriginCrm', true)
        }
      }
      this.$emit('updateFetchIdsParameters', { contactFilter: this.contactFilter, showFilter: true })
    }
  },

  mounted () {
    this.$emit('updateFetchIdsParameters', { contactFilter: DeleteLeadsContactFilter.CUSTOM, showFilter: false })
  },

  methods: {
    reset () {
      this.contactFilter = null
      this.state = 'IDLE'
    },
    close () {
      this.$emit('close')
    },
    submit () {
      this.dialog = false
      this.state = 'LOADING'
      this.loadingTimeout = setTimeout(() => {
        this.state = 'SUBMITTED'
        this.$emit('deleteLeads')
      }, 7000)
    }

  }
}
</script>

<style scoped>
.finished-step{
  border: 1px solid var(--primary-light);
  background: var(--primary-light);
}

.blink{
  animation: blink-animation 4s linear infinite;
  -webkit-animation: blink-animation 4s linear infinite;
}

.-mt-4 {
  margin-top: -4px;
}

@keyframes blink-animation {
  0% {
    color: rgba(0, 0, 0, 0.6);
  }

  50% {
    color: var(--primary);
  }

  100% {
    color: rgba(0, 0, 0, 0.6);
  }
}
</style>
