<template>
  <v-dialog
    v-model="show"
    transition="dialog-bottom-transition"
    max-width="600"
    persistent
  >
    <v-card>
      <v-card-title
        class="pa-0"
      >
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-toolbar-title>
            <v-icon
              medium
              left
            >
              mdi-alert
            </v-icon>
            {{ $t('assign-leads.title') }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="close"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>

      <v-card-text
        class="mt-4"
      >
        <p
          v-if="leadCount > 1"
          v-html="$t('assign-leads.dialog.assign-many', { amount: leadCount, userName: userName })"
        />
        <p
          v-else
          v-html="$t('assign-leads.dialog.assign-one', { amount: leadCount, userName: userName })"
        />
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn
          text
          @click="close"
        >
          {{ $t('labels.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          @click="submit"
        >
          {{ $t('assign-leads.title') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    leadCount: {
      type: Number,
      default: 0
    },
    userName: {
      type: String,
      default: ''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    submit () {
      this.$emit('submit')
    }
  }
}
</script>
