<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="600"
    persistent
  >
    <v-card>
      <v-card-title
        class="pa-0"
      >
        <v-toolbar
          color="error"
          dark
          flat
        >
          <v-toolbar-title>
            <v-icon
              medium
              left
            >
              mdi-alert
            </v-icon>
            {{ $t('labels.caution') }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="close"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>

      <v-card-text
        class="mt-4"
      >
        <p
          v-if="leadCount > 1"
          v-html="$t('delete-leads.dialog.delete-many', { amount: leadCount })"
        />
        <p
          v-else
          v-html="$t('delete-leads.dialog.delete-one', { amount: leadCount })"
        />
        <p>{{ $t('delete-leads.dialog.body-texts.0') }}</p>
        <p>{{ $t('delete-leads.dialog.body-texts.1') }}</p>

        <v-text-field
          v-model="deleteConfirmation"
          outlined
          :label="$t('delete-leads.dialog.placeholder')"
          :placeholder="$t('buttons.delete-leads.delete-permanently')"
          :rules="[rules.required]"
        />
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn
          text
          @click="close"
        >
          {{ $t('labels.cancel') }}
        </v-btn>
        <v-btn
          color="error"
          :disabled="!canDelete"
          @click="deleteLeads"
        >
          {{ $t('buttons.delete-leads.delete-permanently') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import crmMixin from '@/mixins/crm'
import brandingMixin from '@/mixins/branding'
import { required } from '@/lib/validation'

const ALLOWED_CONFIRMATIONS = [
  'Endgültig löschen',
  'Permanently delete'
]

export default {
  mixins: [crmMixin, brandingMixin],
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    leadCount: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      deleteConfirmation: '',
      canDelete: false,
      rules: { required }
    }
  },
  watch: {
    deleteConfirmation (value) {
      if (value && ALLOWED_CONFIRMATIONS.includes(value)) this.canDelete = true
      else this.canDelete = false
    }
  },
  methods: {
    close () {
      this.deleteConfirmation = ''
      this.$emit('close')
    },
    deleteLeads () {
      this.deleteConfirmation = ''
      this.$emit('delete')
    }
  }
}
</script>
