<template>
  <v-card
    outlined
    class="d-flex flex-column"
    :style="cssProps"
  >
    <v-card-title>
      <h5>{{ $t('assign-leads.title') }}</h5>
      <v-spacer />
      <v-btn
        icon
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <p>
        {{ $t('assign-leads.subtitle') }}
      </p>

      <div>
        <div
          class="mt-4 mb-2 rounded-lg mx-n2 pa-2"
          :class="{'finished-step':isContactsSelected}"
        >
          <div
            class="d-flex"
          >
            <v-icon
              v-if="!isContactsSelected"
              :class="{'blink':!isContactsSelected}"
              small
            >
              mdi-arrow-right
            </v-icon>
            <h4 :class="{'blink':!isContactsSelected}">
              {{ $t('assign-leads.body-texts.0') }}
            </h4>

            <v-icon
              v-if="isContactsSelected"
              class="ml-2"
              size="20"
              small
              color="primary"
            >
              mdi-check-circle
            </v-icon>
          </div>
          <p>{{ $t('assign-leads.body-texts[1]') }}</p>

          <v-btn
            depressed
            small
            class="w-full mb-4"
            @click="showFilter"
          >
            {{ $t('labels.filter-contacts') }}
          </v-btn>

          <slot name="selectAll" />
        </div>

        <div
          class="mt-4 mb-2 rounded-lg mx-n2 pa-2"
          :class="{'finished-step':isUserSelected}"
        >
          <div
            class="d-flex"
          >
            <v-icon
              v-if="!isUserSelected && isContactsSelected"
              :class="{'blink':!isUserSelected && isContactsSelected}"
              small
            >
              mdi-arrow-right
            </v-icon>
            <h4 :class="{'blink':!isUserSelected && isContactsSelected}">
              {{ $t('assign-leads.body-texts.2') }}
            </h4>

            <v-icon
              v-if="isUserSelected"
              class="ml-2"
              size="20"
              small
              color="primary"
            >
              mdi-check-circle
            </v-icon>
          </div>
          <p>{{ $t('assign-leads.body-texts.3') }}</p>

          <v-select
            v-model="selectedUserId"
            :items="usersForSelect"
            :placeholder="$t('labels.employee')"
            hide-details
            outlined
            dense
            clearable
          />
        </div>

        <div
          class="mt-4 mb-2 rounded-lg mx-n2 pa-2"
          :class="{'finished-step':isSubmitted}"
        >
          <div class="d-flex">
            <v-icon
              v-if="isUserSelected && isContactsSelected"
              :class="{'blink':isUserSelected && isContactsSelected}"
              small
            >
              mdi-arrow-right
            </v-icon>
            <h4 :class="{'blink':isUserSelected && isContactsSelected}">
              {{ $t('assign-leads.body-texts.4') }}
            </h4>
            <v-icon
              v-if="isSubmitted"
              class="ml-2"
              size="20"
              small
              color="primary"
            >
              mdi-check-circle
            </v-icon>
          </div>

          <div
            v-if="isLoading"
            class="d-flex flex-column align-center justify-center mb-4"
          >
            <span class="text-center">
              {{ $t('assign-leads.body-texts.5') }}
            </span>
          </div>

          <p v-else>
            {{ $t('assign-leads.body-texts.6', {userName: userName }) }}
          </p>

          <v-btn
            depressed
            small
            color="primary"
            :loading="isAssigningLeads || isLoading"
            :disabled="!isUserSelected || !isContactsSelected || isAssigningLeads || isLoading"
            class="w-full"
            @click="showDialog=true"
          >
            <v-icon
              small
              class="mr-2"
            >
              mdi-account-arrow-right-outline
            </v-icon>
            {{ $t('assign-leads.title') }}
          </v-btn>
        </div>
      </div>
    </v-card-text>
    <AssignLeadsDialog
      :show="showDialog"
      :lead-count="selectedItemsLength"
      :user-name="userName"
      @close="showDialog=false"
      @submit="submit"
    />
  </v-card>
</template>

<script>
import { sortByString } from '@/lib/sort-functions'
import USERS from '../queries/Users.gql'
import AnimationData from '../../../../public/animations/email-plane.json'
import AssignLeadsDialog from './AssignLeadsDialog.vue'

export default {
  components: {
    AssignLeadsDialog
  },
  props: {
    selectedItemsLength: {
      type: Number,
      default: 0
    },
    triggerResetAssignLeads: {
      type: Boolean,
      default: false
    },
    isAssigningLeads: {
      type: Boolean,
      default: false
    },
    hasCrm: {
      type: Boolean,
      default: false
    },
    crmName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      state: 'IDLE',
      loadingTimeout: null,
      selectedUserId: null,
      animationData: AnimationData,
      showDialog: false
    }
  },
  computed: {
    usersForSelect () {
      return (this.users ?? [])
        .map(user => ({
          value: user.id,
          text: `${user.firstname} ${user.lastname}`
        }))
        .sort(sortByString('text'))
    },
    userName () {
      return this.usersForSelect.find(user => user.value === this.selectedUserId)?.text
    },
    cssProps () {
      return {
        '--primary': this.$vuetify.theme.themes.light.primary,
        '--primary-light': `${this.$vuetify.theme.themes.light.primary}55`
      }
    },
    isContactsSelected () {
      return this.selectedItemsLength > 0
    },
    isUserSelected () {
      return this.selectedUserId
    },
    isSubmitted () {
      return this.state === 'SUBMITTED'
    },
    isLoading () {
      return this.state === 'LOADING'
    }
  },

  watch: {
    triggerResetAssignLeads () {
      if (this.triggerResetAssignLeads) {
        this.reset()
        this.$emit('triggeredResetAssignLeads')
      }
    }
  },

  mounted () {
    this.state = 'IDLE'
    this.$emit('updateFetchIdsParameters', { contactFilter: 'CUSTOM', showFilter: false })
  },

  methods: {
    reset () {
      this.state = 'IDLE'
      this.selectedUserId = null
      this.$emit('close')
    },
    close () {
      this.$emit('close')
    },
    showFilter () {
      this.$emit('updateFetchIdsParameters', { contactFilter: 'CUSTOM', showFilter: true })
    },
    submit () {
      this.showDialog = false
      this.state = 'LOADING'
      this.loadingTimeout = setTimeout(() => {
        this.state = 'SUBMITTED'
        this.$emit('assignLeads', this.selectedUserId)
      }, 2000)
    }

  },
  apollo: {
    users: {
      query: USERS,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    }
  }
}
</script>

<style scoped>
.finished-step{
  border: 1px solid var(--primary-light);
  background: var(--primary-light);
}

.blink{
  animation: blink-animation 4s linear infinite;
  -webkit-animation: blink-animation 4s linear infinite;
}

.-mt-4 {
  margin-top: -4px;
}

@keyframes blink-animation {
  0% {
    color: rgba(0, 0, 0, 0.6);
  }

  50% {
    color: var(--primary);
  }

  100% {
    color: rgba(0, 0, 0, 0.6);
  }
}
</style>
