import AvailableStartDate from '@/lib/enums/propertyRequest/AvailableStartDate'

export default {
  methods: {
    isPropertyRequestUrgent (lead, type) {
      return Boolean(
        lead.conversions &&
        type === 'PROPERTY_REQUEST' &&
        lead.conversions.find(
          (current) => current.propertyRequest?.availableStartDate && current.propertyRequest.availableStartDate === AvailableStartDate.EARLIEST
        ) &&
        lead.currentStatus === 'new'
      )
    }
  }
}
