<template>
  <create-lead-dialog
    class="w-full"
    :refetch-parent="refetchParent"
  >
    <template v-slot:activator>
      <slot />
    </template>
  </create-lead-dialog>
</template>

<script>
export default {
  components: {
    CreateLeadDialog: () => import('../CreateLeadDialog')
  },
  props: {
    refetchParent: {
      type: Function,
      default: () => {}
    }
  }
}
</script>
