
export default {
  methods: {
    getInitials ({ firstname, lastname }) {
      return [firstname, lastname]
        .map((str) => (typeof str === 'string' ? str.charAt(0) : ''))
        .join('')
        .toUpperCase()
    },
    hasName ({ firstname, lastname }) {
      return Boolean(firstname || lastname)
    },
    formatName ({ firstname, lastname }) {
      if (!firstname || !lastname) {
        return ''
      }
      return `${firstname} ${lastname}`
    }
  }
}
