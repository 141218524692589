export const CONVERSION_TYPES = {
  VALUATION: {
    type: 'VALUATION',
    label: 'leads.table.actions.valuation',
    color: 'blue lighten-4'
  },
  PLOT_VALUATION: {
    type: 'PLOT_VALUATION',
    label: 'leads.table.actions.plot-valuation',
    color: 'purple lighten-4'
  },
  RETURN_CALL: {
    type: 'RETURN_CALL',
    label: 'leads.table.actions.return-call',
    color: 'teal lighten-4'
  },
  LIFE_ANNUITY: {
    type: 'LIFE_ANNUITY',
    label: 'leads.table.actions.life-annuity',
    color: 'cyan lighten-4'
  },
  PARTIAL_SALE: {
    type: 'PARTIAL_SALE',
    label: 'leads.table.actions.partial-sale',
    color: 'lime lighten-4'
  },
  PROPERTY_REQUEST: {
    type: 'PROPERTY_REQUEST',
    label: 'leads.table.actions.property-request',
    color: 'brown lighten-4'
  }
}
