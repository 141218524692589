<template>
  <v-card
    class="mx-auto lead-card"
  >
    <v-card-title class="d-flex justify-center pb-1">
      <v-icon
        large
        color="primary"
      >
        mdi-account-circle avatar
      </v-icon>
    </v-card-title>
    <v-card-text>
      <p class="py-0 mb-1">
        {{ $t('leads-placeholder.title') }}
      </p>
      <p class="py-0 mb-0">
        {{ $t('leads-placeholder.text') }}
      </p>
    </v-card-text>
  </v-card>
</template>
<script>
export default {}
</script>

<style scoped>
.lead-card {
  text-align: center;
  border-radius: 4px !important;
  box-shadow: none !important;
  background: #FFFFFF;
  max-width: 400px;
}
</style>
