<template>
  <div>
    <v-chip
      v-for="(tag, index) in tags"
      :key="index"
      color="#F0F0F0"
      x-small
      class="ml-1 float-left mb-2"
    >
      {{ tag }}
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    tags: {
      type: Array,
      required: true
    }
  }
}
</script>
