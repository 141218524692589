<template>
  <div
    class="px-2 option-container d-flex align-center"
    :class="{'w-full':$vuetify.breakpoint.xs}"
  >
    <v-btn
      outlined
      color="primary"
      class="camel-case option-btn"
      @click="click"
    >
      <v-icon left>
        {{ icon }}
      </v-icon>
      {{ label }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  },
  methods: {
    click () {
      this.$emit('click')
    }
  }
}
</script>
