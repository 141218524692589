import featureNames from '@/lib/featureNames'

export const hasPropertyValue = (item) => {
  if (!item || !item.conversions) return false

  return item.conversions.some(conversion => conversion.type === featureNames.VALUATION.toUpperCase())
}

const getValuation = (item) => {
  if (!item || !item.conversions) return null

  return item.conversions.find(conversion => conversion.type === featureNames.VALUATION.toUpperCase())
}

const getValuationData = (item) => {
  const valuation = getValuation(item)
  if (
    !valuation ||
    !valuation.valuation ||
    !valuation.valuation.results ||
    !valuation.valuation.results.property ||
    !valuation.valuation.results.currency) return null

  const value = valuation.valuation.results.property?.value
  if (!value || value === 0) { return null }
  const currency = valuation.valuation.results.currency

  return { value, currency }
}

export const propertyValue = (item, formatPrice) => {
  const valuationData = getValuationData(item)
  if (!valuationData) return null

  const { value, currency } = valuationData
  if (!value || value <= 0) { return null }
  return formatPrice ? formatPrice(value, currency) : { value, currency }
}

export const commissionValue = (item, commission, formatPrice) => {
  const valuationData = getValuationData(item)
  if (!valuationData) return null

  const { value, currency } = valuationData
  const commissionAmount = value * (commission / 100)
  if (!commissionAmount || commissionAmount <= 0) { return null }

  return formatPrice ? formatPrice(commissionAmount, currency) : { commissionAmount, currency }
}
