<template>
  <v-row
    class="ma-0"
    style="max-width:100%"
  >
    <v-col
      class="pa-0"
      style="max-width:100%; overflow-x: scroll; height: calc(-150px + 100vh);"
    >
      <div class="min-h-screen lead-cards">
        <div
          v-for="(column, index) in leadsList"
          :key="column.title"
          ref="scrollColumn"
          class="px-2 my-3 mr-1 rounded column-width draggable-area"
          @scroll="handleVerticalScroll(index, column.loading)"
          @mousemove="handleVerticalCardDrag(index)"
        >
          <p class="pb-4 mb-0 title sticky-title">
            {{ $t(column.title) }}
          </p>
          <draggable
            ref="draggableContainer"
            :scroll="true"
            :scroll-sensitivity="300"
            :scroll-speed="10"
            :move="handleHorizontalCardDrag"
            :list="column.leads"
            :animation="200"
            class="draggable"
            ghost-class="ghost-card"
            group="leads"
            @change="onChange($event, index)"
          >
            <div
              v-for="(task, index) in column.leads"
              :key="task.id"
            >
              <LeadCard
                :index="index"
                :item="task"
                :widget="task"
                :conversion-types="conversionTypes"
                :is-last-conversion-at-visible="isLastConversionAtVisible"
                :are-conversions-visible="areConversionsVisible"
                :is-email-confirmed-visible="isEmailConfirmedVisible"
                :is-assigned-user-visible="isAssignedUserVisible"
                :are-tags-visible="areTagsVisible"
                :is-valuation-visible="isValuationVisible"
                :is-commission-visible="isCommissionVisible"
                :users="users"
                :commission="commission"
                :class="{ 'mt-3' : index !== 0 }"
                class="cursor-move"
                @assignUser="assignUser"
              />
            </div>
            <div slot="footer">
              <PlaceholderCard v-if="column.leads.length === 0" />
              <ProgressCircular
                v-if="column.loading"
                :size="50"
                class="progress-circular"
              />
              <div class="footer-spacer" />
            </div>
          </draggable>
        </div>
        <div
          v-if="leadsByStatusLoading"
          class="px-2 my-3 column-width"
        >
          <ProgressCircular :size="50" />
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<script>

import PlaceholderCard from './PlaceholderCard.vue'
import { CONVERSION_TYPES } from '../../configs/conversionTypes'
import ProgressCircular from '@/components/ProgressCircular.vue'
import { Status } from '@/modules/leads/enums/Status'
import ASSIGN_USER from '@/modules/leads/Lead/queries/AssignUser.gql'
import { showSnackbarMessage } from '@/lib/snackbarMessages'
import UPDATE_LEAD_STATUS from '@/modules/leads/Lead/queries/UpdateLeadStatus.gql'
import leadStatus from '@/lib/leadStatus'

export default {
  components: {
    ProgressCircular,
    PlaceholderCard,
    draggable: () => import(/* webpackChunkName: "import" */ 'vuedraggable'),
    LeadCard: () => import('./LeadCard.vue')

  },
  props: {
    commission: {
      type: String,
      default: ''
    },
    itemsPerPage: {
      type: Number,
      default: 20
    },
    leadsByStatusLoading: {
      type: Boolean,
      default: false
    },
    leadsByStatus: {
      type: Array,
      default: () => []
    },
    users: {
      type: Array,
      default: () => []
    },
    listOptions: {
      type: Object,
      required: true
    },
    columnSettings: {
      type: Array,
      required: true
    },
    sortLeadsByPosition: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      scrollSpeed: 10,
      draggableHeight: 0,
      loadMoreLeads: false,
      leadsList: [],
      pendingAddOperation: false,
      pendingRemoveOperation: false
    }
  },
  computed: {

    isLastConversionAtVisible () {
      return this.isColumnVisible('lastConversionAt')
    },
    areConversionsVisible () {
      return this.isColumnVisible('conversions')
    },
    isEmailConfirmedVisible () {
      return this.isColumnVisible('confirmedAt')
    },
    isAssignedUserVisible () {
      return this.isColumnVisible('assignedUser')
    },
    areTagsVisible () {
      return !!this.isColumnVisible('propstackCrmTags') ||
             !!this.isColumnVisible('flowfactCrmTags') ||
             !!this.isColumnVisible('onofficeCrmTags')
    },
    isValuationVisible () {
      return !!this.isColumnVisible('propertyValuation')
    },
    isCommissionVisible () {
      return !!this.isColumnVisible('commission')
    },
    conversionTypes () {
      return Object.values(CONVERSION_TYPES).filter(({ type }) => this.isColumnVisible(`conversionCountByType.${type}`))
    }
  },
  watch: {
    leadsByStatus (val) {
      const searchStringPresent = this.listOptions.search?.length > 0
      if (searchStringPresent) {
        this.leadsList = val
      } else if (this.loadMoreLeads) {
        this.loadMoreLeads = false
        const statusToUpdate = val[0].status
        const existingLead = this.leadsList.find(item => item.status === statusToUpdate)

        if (existingLead) {
          const uniqueLeads = [...existingLead.leads, ...val[0].leads].filter(
            (lead, index, self) =>
              index === self.findIndex((t) => t.id === lead.id)
          )

          const sortLeadsByPosition = this.sortLeadsByPosition(uniqueLeads)
          const updatedLeads = {
            ...existingLead,
            page: val[0].page,
            leads: [...sortLeadsByPosition],
            loading: false,
            total: val[0].total
          }

          this.leadsList = this.leadsList.map(item =>
            item.status === statusToUpdate ? updatedLeads : { ...item, loading: false }
          )
        }
      } else {
        this.leadsList = val
      }
    }
  },
  mounted () {
    /* eslint-disable-next-line no-unused-expressions */
    this.$refs.scrollColumn?.addEventListener('scroll', this.handleVerticalScroll)
  },
  methods: {
    async assignUser (id, userForSelect) {
      try {
        await this.$apollo.mutate({
          mutation: ASSIGN_USER,
          variables: {
            leadId: id,
            userId: userForSelect.value
          }
        })

        this.updateLeadsList(id, userForSelect)
        this.$tracking.event('Leads', 'Clicked', 'Assign User')
        showSnackbarMessage('success', this.$t('alerts.assign-leads.success', { userName: userForSelect.text }))
      } catch (e) {
        showSnackbarMessage('error', this.$t('alerts.assign-leads.error'))
      }
    },

    updateLeadsList (id, userForSelect) {
      const leadsList = this.leadsList.find(lead =>
        lead.leads.some(item => item.id === id)
      )

      if (!leadsList) { return }

      const { firstName, lastName, value } = userForSelect

      leadsList.leads = leadsList.leads.map(item => {
        if (item.id === id) {
          return {
            ...item,
            assignedUser: {
              ...userForSelect,
              firstname: firstName,
              lastname: lastName,
              id: value
            }
          }
        }
        return item
      })
    },

    hasMoreItems ({ total, page }) {
      const totalPages = Math.ceil(total / this.itemsPerPage)
      return page < totalPages
    },

    handleCardDrag (event, axis, container, threshold = 600, scrollSpeed = 20) {
      if (!container) return

      const containerRect = container.getBoundingClientRect()
      const mousePosition = axis === 'y' ? event.clientY : event.clientX

      if (axis === 'y') {
        if (mousePosition >= containerRect.bottom - threshold && mousePosition <= containerRect.bottom) {
          container.scrollTop += scrollSpeed
        } else if (mousePosition >= containerRect.top && mousePosition <= containerRect.top + threshold) {
          container.scrollTop -= scrollSpeed
        }
      } else if (axis === 'x') {
        if (mousePosition >= containerRect.right - threshold && mousePosition <= containerRect.right) {
          container.scrollLeft += scrollSpeed
        } else if (mousePosition >= containerRect.left && mousePosition <= containerRect.left + threshold) {
          container.scrollLeft -= scrollSpeed
        }
      }
    },

    handleVerticalCardDrag (index) {
      return (event) => {
        this.handleCardDrag(event, 'y', this.$refs.scrollColumn[index], 600, 20)
      }
    },

    handleHorizontalCardDrag (event, originalEvent) {
      this.handleCardDrag(originalEvent, 'x', this.$refs.draggableContainer.$el, 600, 20)
    },
    async handleVerticalScroll (index, loading) {
      if (loading) { return }

      const container = this.$refs.scrollColumn[index]
      if (!container) { return }

      const hasReachedBottom = container.scrollTop + container.clientHeight >= container.scrollHeight
      if (!hasReachedBottom) { return }

      const lead = this.leadsList[index]
      if (!lead) { return }

      if (this.hasMoreItems(lead)) {
        this.loadMoreLeads = true
        lead.loading = true
        const statusesToLoad = lead.status === Status.NEW ? [Status.NEW, Status.CREATED] : [lead.status]
        await this.getLeadsByStatus(statusesToLoad, lead.page + 1)
      }
    },
    async getLeadsByStatus (statuses, page) {
      this.$emit('getLeadsByStatus', statuses, page)
    },

    async onChange (event, index) {
      if (event.added) {
        this.pendingAddOperation = true
        const addedLead = {
          id: event.added.element.id,
          position: event.added.newIndex
        }

        const leadColumn = this.leadsList[index]

        if (leadColumn) {
          addedLead.status = leadColumn.status
          await this.updateLeadStatus(addedLead.id, addedLead.status)

          const changedLead = leadColumn.leads.find((lead) => lead.id === addedLead.id)
          changedLead.currentStatus = addedLead.status

          this.handleUpdatePositions(addedLead.status)
        }
      }

      if (event.removed || event.moved) {
        const eventStatus = event.removed ? event.removed.element.currentStatus : event.moved.element.currentStatus
        this.handleUpdatePositions(eventStatus)
      }
    },

    async updateLeadStatus (leadId, status) {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_LEAD_STATUS,
          variables: {
            leadId: leadId,
            status: status
          }
        })

        this.$tracking.event('Leads', 'Clicked', 'Update Lead Status')
        showSnackbarMessage('success', this.$t('alerts.lead-status.success', { status: this.$t(leadStatus[status]) }))
      } catch (e) {
        showSnackbarMessage('error', this.$t('alerts.lead-status.error'))
      }
    },

    handleUpdatePositions (status) {
      const column = this.leadsList.find(
        (column) => column.status === status || (status === 'created' && column.status === 'new')
      )

      if (!column) {
        return
      }

      const listIds = column.leads.map(lead => lead.id)

      if (listIds.length > 0) {
        this.$emit('updateLeadsPosition', listIds)
      }
    },

    isColumnVisible (key) {
      return this.columnSettings.find((setting) => setting.value === key)?.visible
    }
  }
}
</script>
<style scoped>
.lead-cards {
  display: flex;
}

.draggable-area {
  height: calc(-200px + 100vh);
  overflow: auto;
}

.column-width {
  min-width: 300px;
  width: 300px;
}

.title {
  color: #252525;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.sticky-title {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #f5f5f5;
  z-index: 8;
}
.ghost-card {
  opacity: 0.5;
  background: #F7FAFC;
}

.progress-circular,  .footer-spacer {
  height: 100px;
}

</style>
