<template>
  <v-tooltip
    :disabled="!isDisabled"
    bottom
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        :class="{'w-full':$vuetify.breakpoint.xs}"
        class="px-2 my-2"
        v-bind="attrs"
        v-on="on"
      >
        <v-btn
          :disabled="isDisabled"
          color="primary"
          class="camel-case w-full"
          @click="click"
        >
          <v-icon
            small
            left
          >
            {{ icon }}
          </v-icon>
          {{ label }}
        </v-btn>
      </div>
    </template>
    <span>Um den Akquise-Booster zu nutzen, aktivieren Sie den neuen E-Mail-Funnel.</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  },
  methods: {
    click () {
      this.$emit('click')
    }
  }
}
</script>
